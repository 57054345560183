import React from "react";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import TemporaryStuffingHeading from "../../../Components/TemporaryStaffingFunction/TemporaryStuffingHeading/TemporaryStuffingHeading";
import TemporaryStuffingParaSection from "../../../Components/TemporaryStaffingFunction/TemporaryStuffingParaSection/TemporaryStuffingParaSection";
import TemporaryStuffingImgSection from "../../../Components/TemporaryStaffingFunction/TemporaryStuffingImgSection/TemporaryStuffingImgSection";
import TemporaryStaffingAboveFooter from "../../../Components/TemporaryStaffingFunction/TemporaryStaffingAboveFooter/TemporaryStaffingAboveFooter";
import "./PositionWeRecurit.css";
import StartupImgOne from "../../../Assets/Images/Apply/jobseeker-additional-resources.jpg";
import { Helmet } from "react-helmet";

const PositionWeRecurit = () => {
  return (
    <div>
      <div className="header-container">
        <Helmet>
          <title>Explore IT Positions</title>
          <meta
            name="description"
            content="Explore exciting IT positions from software development to cybersecurity, discover rewarding career opportunities in Information Technology. Join our dynamic team and take your career to new heights in the ever-evolving world of IT."
          />
          <meta
            name="keywords"
            content="Open positions, Available roles, Vacant positions, Employment opportunities"
          />
        </Helmet>
        <div className="">
          <Header />
        </div>
      </div>
      <div className="itContainer wrapper pwr-ctn">
        <TemporaryStuffingHeading
          h1={[
            "We Recruit Top-tier Technology Talent for Growing Brands and Tech Employers.",
          ]}
          h3={[
            "Unlock Your Potential with Exceptional Talent and Technology Opportunities.",
          ]}
        />
        <div className="itContentContainer">
          <div className="itLeftContainer">
            <TemporaryStuffingParaSection
              MainHeading=""
              paragraph1="Tech Sierra Staffing & Recruiting is a nationally acclaimed technology recruitment, creative, and IT staffing agency. Our seasoned technology recruitment team dedicates time to understand our candidates, ensuring exceptional talent matches. Our priority is to forge perfect fits between candidate talents and client opportunities. As a socially conscious technology recruiting firm and industry leader, we strive for excellence in serving both clients and candidates. With regional headquarters across all 50 states, we recruit for diverse technology, software engineering, creative marketing, and innovative emerging technology roles."
            />
            <div>
              <TemporaryStuffingParaSection
                MainHeading="For Technology Clients in Need of Hiring, We:"
                paragraph1=""
              />
              <ul>
                <li>
                  <p>
                    Offer access to our extensive national and local networks of
                    technology professionals.
                  </p>
                </li>
                <li>
                  <p>
                    Provide prompt assistance, swift engagement in the search
                    process, and a commitment to surpassing your hiring timeline
                    and specifications.
                  </p>
                </li>
                <li>
                  <p>
                    Invite you to explore further details about our esteemed
                    technology recruitment firm through sections like Why Choose
                    Tech Sierra, Our Clients, and Our Mission & Values.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <TemporaryStuffingParaSection
                MainHeading="During Our Search Process For Qualified Candidates, We Aim to:"
                paragraph1=""
              />
              <ul>
                <li>
                  <p>
                    Present multiple IT/Tech job opportunities that align with
                    your background.
                  </p>
                </li>
                <li>
                  <p>
                    Assist you in finding the right opportunity and working
                    environment.
                  </p>
                </li>
                <li>
                  <p>
                    Prepare you for a successful interview with tips and advice.
                  </p>
                </li>
                <li>
                  <p>
                    Advocate for favorable salary and benefits negotiations.
                  </p>
                </li>
                <li>
                  <p>Clarify the role, employer objectives, and preferences.</p>
                </li>
                <li>
                  <p>
                    Assist in your smooth transition into the new position and
                    start date.
                  </p>
                </li>
                <li>
                  <p>
                    Maintain ongoing communication post-hiring, serving as your
                    dedicated recruitment ally.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <TemporaryStuffingParaSection
                MainHeading="The Benefits Your Tech Sierra Staffing & Recruiting Staffing Recruiter May Connect You with Can Include:"
                paragraph1=""
              />
              <ul>
                <li>
                  <p>Lucrative compensation packages, whether hourly or annual.</p>
                </li>
                <li>
                  <p>
                  Comprehensive medical, vision, and dental coverage for permanent roles.
                  </p>
                </li>
                <li>
                  <p>Paid sick leave, holidays, and/or vacation time.</p>
                </li>
                <li>
                  <p>Assistance with relocation, if needed.</p>
                </li>
                <li>
                  <p>Possible stock options at our startup clients.</p>
                </li>
                <li>
                  <p>
                    401k, bonus plans, signing bonuses, and/or commuter
                    benefits.
                  </p>
                </li>
                <li>
                  <p>A competitive raise from your current salary.</p>
                </li>
                <li>
                  <p>
                    A professional and efficient pathway to meet hard-to-reach
                    top employers.
                  </p>
                </li>
                <li>
                  <p>Assurance of landing a job you'll truly enjoy!</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="itRightContainer">
            <TemporaryStuffingImgSection
              buttonText={["Find the Best Leader"]}
              btnText={["Apply Online"]}
              buttonLink="/contactUs"
              imageUrl={StartupImgOne}
            />
            <TemporaryStuffingImgSection
              buttonText={["Hire the Best Tech Professionals"]}
              btnText={["Requesting Staff"]}
              buttonLink="/contactUs"
              // imageUrl={StartupImgOne}
            />
          </div>
        </div>
        <div className="pwr-btm-ctn">
          <h2 className="pwr-btm-heading">
            Tech Jobs Available Through Tech Sierra
          </h2>
          <div className="pwr-btm-lists">
            <div>
              <h4>Technology Executive Leadership</h4>
              <ul>
                <li>Chief Executive Officer (CEO)</li>
                <li>Chief Technical Officer (CTO)</li>
                <li>Chief Information Officer (CIO)</li>
                <li>VP of Engineering (VP)</li>
                <li>VP of Technology (VP)</li>
                <li>Security Officer (CISO)</li>
                <li>Chief Product Officer (CPO)</li>
                <li>Digital Officers (CDO)</li>
              </ul>
            </div>
            <div>
              <h4>
                IT: Systems, Security, Data & Networking (Permanent Placement &
                Contract)
              </h4>
              <ul>
                <li>Director of IT</li>
                <li>IT Manager</li>
                <li>Business Intelligence Analyst</li>
                <li>Database Administrator</li>
                <li>Data Analyst</li>
                <li>HelpDesk – Specialist, Supervisor</li>
                <li>Network Administrator</li>
                <li>IT Support</li>
                <li>Systems Engineer</li>
                <li>Data Scientist</li>
                <li>Network Engineer</li>
              </ul>
            </div>
            <div>
              <h4>Creative: Marketing & Design (Contract & Direct Hire)</h4>
              <ul>
                <li>Digital Designer / Producer</li>
                <li>Graphic Designer</li>
                <li>Product Manager</li>
                <li>Project Manager</li>
                <li>SEO Analyst</li>
                <li>Marketing Specialist/Manager</li>
                <li>Art Director</li>
                <li>Creative Director</li>
                <li>Technical Writers</li>
              </ul>
            </div>
            <div>
              <h4>Software & Engineering (Permanent Placement & Contract)</h4>
              <ul>
                <li>Engineering Manager</li>
                <li>Software Engineers & Developer</li>
                <li>Full-Stack Software Engineer/Developer</li>
                <li>DevOps Engineer</li>
                <li>Machine Learning Engineer</li>
                <li>Backend/Frontend Engineer</li>
                <li>Data Engineer</li>
                <li>Security Engineer</li>
                <li>Cloud Engineer</li>
                <li>Architect</li>
                <li>Mobile Developer</li>
                <li>Product Developer</li>
                <li>UI/UX Designer</li>
              </ul>
            </div>
            <div>
              <h4>Trending Skills:</h4>
              <ul>
                <li>Office 365</li>
                <li>Remote Desktop</li>
                <li>Citrix</li>
                <li>Active Directory</li>
                <li>Routing/Switching/Firewall Configuration</li>
                <li>PowerShell</li>
                <li>Onboarding</li>
                <li>Ticketing Systems</li>
                <li>CCISP certification</li>
                <li>CCNA certification</li>
              </ul>
            </div>

            <div>
              <h4>Trending Skills:</h4>

              <ul>
                <li>Google Analytics</li>
                <li>Google Tag Manager</li>
                <li>Sketch</li>
                <li>Adobe</li>
                <li>Copywriting</li>
              </ul>
            </div>

            <div>
              <h4>Trending Skills:</h4>

              <ul>
                <li>Spark</li>
                <li>Scala</li>
                <li>JavaScript</li>
                <li>Python</li>
                <li>Java</li>
                <li>.NET</li>
                <li>Cloud: AWS, G-Cloud, Azure</li>
                <li>Distributed Systems</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pwr-above-footer">
          <TemporaryStaffingAboveFooter
            bottomLinks={[
              { text: "apply with us", url: "/apply/apply-with-us" },
              { text: "view our open positions", url: "/apply/search-it-jobs" },
              { text: "interview tips", url: "/apply/it-job-interview-tips" },
            ]}
            h2={["More Information"]}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PositionWeRecurit;
