import React from "react";
import "./OurMissionValue.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import missionandvalues from "../../../Assets/Images/aboutus.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const OurMissionValue = () => {
  return (
    <div>
      <div className="header-container">
        <Helmet>
          <title>Mission & Vision</title>
          <meta
            name="description"
            content="Get to know us better! Explore our About Us page to uncover the story behind our mission, values, and dedication to serving you. Learn about our team, culture, and how we're making a difference in the industry."
          />
          <meta name="keywords" content="Company Overview, Values, Our Story" />
        </Helmet>
        <div className="">
          <Header />
        </div>
      </div>
      <div className="mission-values-container">
        <div className="wrapper">
          <h2 className="section-title">Our Mission &amp; Vision</h2>
          <div className="mission-statement-ctn">
            <p className="mission-statement">
              At TechSierra, we're dedicated to providing the nation's premier
              staffing and executive search services, driven by a commitment to
              ethical business practices. We lead the way by exemplifying
              operational excellence, prioritizing client and employee
              satisfaction, fostering professional integrity, advocating for
              diversity, equity, and inclusion, and unlocking the true potential
              of our industry.
            </p>
          </div>
          <div className="mission-values-main">
            <div>
              <h3 className="subsection-title">Our Name</h3>
              <p className="subsection-content">
                The name "Tech Sierra Staffing & Recruiting" symbolizes our
                approach, akin to a plant's shoot that, when transplanted to a
                new environment, fosters growth. We strategically position fresh
                talent to enhance organizational development and progress.
              </p>

              <h3 className="subsection-title">Our Values</h3>
              <div className="value-section">
                <h4 className="value-title">DIVERSITY &amp; INCLUSION</h4>
                <p className="value-description">
                  We firmly believe that diverse teams are not only advantageous
                  but vital for fostering innovation and gaining a competitive
                  edge. Our commitment extends to inclusive, equitable, and
                  unbiased application processes, interviews, and coaching
                  methods. It's essential for us to authentically represent our
                  firm and uphold these values in the partnerships and
                  connections we forge.
                </p>
              </div>

              <div className="value-section">
                <h4 className="value-title">INTEGRITY &amp; ETHICS</h4>
                <p className="value-description">
                  Our dedication to excellence drives us in every endeavor, as
                  we endeavor to provide enduring value to our clients,
                  candidates, partners, and team members.
                </p>
              </div>

              <div className="value-section">
                <h4 className="value-title">SERVICE EXCELLENCE</h4>
                <p className="value-description">
                  We are committed to excellence in everything we do, striving
                  to deliver lasting value to our clients, candidates, partners,
                  and staff.
                </p>
              </div>

              <div className="value-section">
                <h4 className="value-title">LEARNING &amp; INNOVATION</h4>
                <p className="value-description">
                  We are committed to continuous improvement, refining our
                  services, processes, communication, and operational
                  frameworks. By harnessing evolving technological tools, we
                  enhance our reach, impact, and expansion. Embracing humility,
                  we seize individual professional growth opportunities,
                  fortifying our company's internal strength.
                </p>
              </div>

              <div className="value-section">
                <h4 className="value-title">PASSION</h4>
                <p className="value-description">
                  We derive satisfaction from enabling our clients' strategic
                  hiring objectives and linking exceptional candidates to new
                  career opportunities. Embracing the chance to elevate and
                  enhance the prospects of both individuals and organizations,
                  we celebrate the transformative impact we facilitate.
                </p>
              </div>

              <div className="value-section">
                <h4 className="value-title">TEAMWORK &amp; POSITIVITY</h4>
                <p className="value-description">
                  Committed to delivering value and inspiring others through our
                  actions, we believe that empathy and positivity are the
                  driving forces behind effective collaboration. Anchored in
                  these core values, we integrate them into every aspect of our
                  organization. Together, we thrive.
                </p>
              </div>
            </div>
            <div>
              <div className="button-container">
                <span className="primary-button">
                  Ready to launch your search?
                </span>
                <p>
                  <Link className="employer_cta_button" to="/contact-us">
                    Connect With Us
                  </Link>
                </p>
              </div>

              <img
                className="mission-image"
                src={missionandvalues}
                alt="Diverse group of people with their hands together"
              />
            </div>
          </div>
          <div className="mission-section-btm-container">
            <h2 className="section-main-title">Learn More About Tech Sierra</h2>

            <ul className="mission-section-btm-links">
              <li>
                <Link to="/hire">request staff</Link>
              </li>
              <li>
                <Link to="/apply/search-it-jobs">search open positions</Link>
              </li>
              <li>
                <Link to="/">our clients</Link>
              </li>
              <li>
                <Link to="/">why choose Tech Sierra</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurMissionValue;
