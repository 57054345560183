import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import TemporaryStaffingAboveFooter from "../../Components/TemporaryStaffingFunction/TemporaryStaffingAboveFooter/TemporaryStaffingAboveFooter";
import ContactMap from "../../Assets/Images/Contact/contact-maps.png";
import ContactUsForm from "../../Components/ContactUsForm/ContactUsForm";
import { Link } from "react-router-dom";
import craigImg from "../../Assets/Images/Craig Santoro - Profile Picture.jpeg";
import { BiLogoLinkedin } from "react-icons/bi";
import { Helmet } from "react-helmet";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact-ctn">
      <Helmet>
        <title>Get in Touch</title>
        <meta
          name="description"
          content="Connect with us effortlessly! Our Contact Us page is your gateway to seamless communication. Reach out for inquiries, feedback, or just to say hello. We're here to listen and assist."
        />
        <meta
          name="keywords"
          content="Contact Us, Reach Out to techsierra, Connect, Contact Information, Contact to techsierra, connect with techsierra"
        />
      </Helmet>
      <div className="header-container">
        <Header />
      </div>
      <div className="wrapper contact-header">
        <h1>Contact Us</h1>
        <h2>
          Temporary Staffing Agency specializing in Technology & IT, Proficient
          in Direct-Hire Recruitment & Executive Search.
        </h2>
      </div>
      <div className="contact-main">
        <div className="wrapper contact-name-locations">
          <div className="craigImg">
            <img src={craigImg} alt="Craig Santoro" />
          </div>
          <p>Craig Santoro</p>
          <p>Vice President - Sales</p>
          <a className="phoneNumber" href="mailto:craig@techsierra.us">
            craig@techsierra.us
          </a>
          <span>
            <a className="phoneNumber" href="tel:603-305-3060">
              603-305-3060
            </a>
          </span>
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              className="LinkdIn"
              href="https://www.linkedin.com/in/craigsantoro"
            >
              <BiLogoLinkedin size={30} />
            </a>
          </span>
          <Link className="twoButtons" to="/contactUs" title="Hire Staff">
            hire staff
          </Link>
          <Link className="twoButtons" to="/contactUs" title="Apply For Jobs">
            apply for jobs
          </Link>
        </div>
        <div className="contact-main-header">
          <h2>We recruit for all U.S. cities and remote roles nationwide.</h2>
        </div>
        <div className="contact-main--outer-header">
          <div className="wrapper contact-main-below-header">
            <div className="contact-address-maps">
              <h3>
                National Technology & IT Staffing For All U.S. Cities and
                Locations
              </h3>
              <ul>{/* List of locations */}</ul>
            </div>
            <figure>
              <img src={ContactMap} alt="Map" />
            </figure>
          </div>
        </div>
        <div className="contact-main-address-section">
          <div className="contact-main-btn-section">
            <h3>
              National Recruiting Agency for Technology, IT, & Engineering,
              Serving All U.S. Regions and Locations
            </h3>
            <div className="contact-two-btns">
              <Link to="/apply/apply-with-us" title="Request staff">
                request staff
              </Link>
              <Link to="/apply/search-it-jobs" title="Search Our Jobs">
                search our jobs
              </Link>
            </div>
          </div>
        </div>
        <div className="contactUsForm">
          <ContactUsForm />
        </div>
        <div className="contact-above-footer">
          <TemporaryStaffingAboveFooter
            bottomLinks={[
              { text: "request technical staff", url: "/contactUs" },
              { text: "award-winning service", url: "/about-us" },
              { text: "positions we recruit", url: "/permanent-placement" },
            ]}
            h2={["Learn More About Tech Sierra"]}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
