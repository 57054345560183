export const jobs = [
  {
    title: "Software Developer",
    state: "Karnataka",
    city: "Bangalore",
    description:
      "Seeking a skilled software developer to join our team in Bangalore.",
  },
  {
    title: "Data Scientist",
    state: "Maharashtra",
    city: "Mumbai",
    description: "We're hiring a data scientist for our Mumbai office.",
  },
  {
    title: "Web Developer",
    state: "Maharashtra",
    city: "Pune",
    description: "Join our team as a web developer in Pune.",
  },
  {
    title: "UI/UX Designer",
    state: "Tamil Nadu",
    city: "Chennai",
    description: "Exciting opportunity for a UI/UX designer in Chennai.",
  },
  {
    title: "Java Developer",
    state: "Uttar Pradesh",
    city: "Noida",
    description: "Join us as a Java developer in Noida.",
  },
  {
    title: "Network Engineer",
    state: "Telangana",
    city: "Hyderabad",
    description: "Looking for a network engineer to work in Hyderabad.",
  },
  {
    title: "Software Engineer",
    state: "Karnataka",
    city: "Mysore",
    description: "Exciting opportunity for a software engineer in Mysore.",
  },
  {
    title: "Machine Learning Engineer",
    state: "Delhi",
    city: "New Delhi",
    description: "Join our team as a machine learning engineer in New Delhi.",
  },
  {
    title: "Frontend Developer",
    state: "Karnataka",
    city: "Hubli",
    description: "Seeking a frontend developer to join our team in Hubli.",
  },
  {
    title: "Backend Developer",
    state: "Gujarat",
    city: "Ahmedabad",
    description: "We're hiring a backend developer for our Ahmedabad office.",
  },
  {
    title: "Database Administrator",
    state: "Uttar Pradesh",
    city: "Lucknow",
    description: "Join us as a database administrator in Lucknow.",
  },
  {
    title: "DevOps Engineer",
    state: "Maharashtra",
    city: "Nagpur",
    description: "Exciting opportunity for a DevOps engineer in Nagpur.",
  },
  {
    title: "System Administrator",
    state: "Tamil Nadu",
    city: "Coimbatore",
    description:
      "We're hiring a system administrator for our Coimbatore office.",
  },
  {
    title: "Full Stack Developer",
    state: "Andhra Pradesh",
    city: "Visakhapatnam",
    description:
      "Seeking a full stack developer to join our team in Visakhapatnam.",
  },
  {
    title: "Product Manager",
    state: "Telangana",
    city: "Warangal",
    description: "Join us as a product manager in Warangal.",
  },
  {
    title: "UI Developer",
    state: "Kerala",
    city: "Kochi",
    description: "Exciting opportunity for a UI developer in Kochi.",
  },
  {
    title: "UX Researcher",
    state: "Madhya Pradesh",
    city: "Bhopal",
    description: "We're hiring a UX researcher for our Bhopal office.",
  },
  {
    title: "Software Architect",
    state: "Rajasthan",
    city: "Jaipur",
    description: "Seeking a software architect to join our team in Jaipur.",
  },
  {
    title: "Mobile App Developer",
    state: "Uttarakhand",
    city: "Dehradun",
    description: "Join us as a mobile app developer in Dehradun.",
  },
  {
    title: "Cloud Engineer",
    state: "West Bengal",
    city: "Kolkata",
    description: "Exciting opportunity for a cloud engineer in Kolkata.",
  },
  {
    title: "Security Analyst",
    state: "Bihar",
    city: "Patna",
    description: "We're hiring a security analyst for our Patna office.",
  },
  {
    title: "Embedded Systems Engineer",
    state: "Punjab",
    city: "Chandigarh",
    description:
      "Seeking an embedded systems engineer to join our team in Chandigarh.",
  },
  {
    title: "QA Engineer",
    state: "Assam",
    city: "Guwahati",
    description: "Join us as a QA engineer in Guwahati.",
  },
  {
    title: "Data Analyst",
    state: "Jharkhand",
    city: "Ranchi",
    description: "Exciting opportunity for a data analyst in Ranchi.",
  },
  {
    title: "Business Analyst",
    state: "Haryana",
    city: "Gurgaon",
    description: "We're hiring a business analyst for our Gurgaon office.",
  },
  {
    title: "IT Support Specialist",
    state: "Himachal Pradesh",
    city: "Shimla",
    description: "Seeking an IT support specialist to join our team in Shimla.",
  },
  {
    title: "Software Tester",
    state: "Goa",
    city: "Panaji",
    description: "Exciting opportunity for a software tester in Panaji.",
  },
  {
    title: "Automation Engineer",
    state: "Manipur",
    city: "Imphal",
    description: "We're hiring an automation engineer for our Imphal office.",
  },
  {
    title: "Technical Writer",
    state: "Nagaland",
    city: "Kohima",
    description: "Seeking a technical writer to join our team in Kohima.",
  },
  {
    title: "Cybersecurity Engineer",
    state: "Meghalaya",
    city: "Shillong",
    description:
      "Exciting opportunity for a cybersecurity engineer in Shillong.",
  },
  {
    title: "Network Administrator",
    state: "Sikkim",
    city: "Gangtok",
    description: "We're hiring a network administrator for our Gangtok office.",
  },
  {
    title: "IT Consultant",
    state: "Arunachal Pradesh",
    city: "Itanagar",
    description: "Seeking an IT consultant to join our team in Itanagar.",
  },
  {
    title: "Database Developer",
    state: "Mizoram",
    city: "Aizawl",
    description: "Exciting opportunity for a database developer in Aizawl.",
  },
  {
    title: "Cloud Architect",
    state: "Tripura",
    city: "Agartala",
    description: "We're hiring a cloud architect for our Agartala office.",
  },
  {
    title: "Systems Analyst",
    state: "Dadra and Nagar Haveli",
    city: "Silvassa",
    description: "Seeking a systems analyst to join our team in Silvassa.",
  },
  {
    title: "Application Developer",
    state: "Daman and Diu",
    city: "Daman",
    description: "Exciting opportunity for an application developer in Daman.",
  },
  {
    title: "Blockchain Developer",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "We're hiring a blockchain developer for our Kavaratti office.",
  },
  {
    title: "Software Support Specialist",
    state: "Andaman and Nicobar Islands",
    city: "Port Blair",
    description:
      "Seeking a software support specialist to join our team in Port Blair.",
  },
  {
    title: "AR/VR Developer",
    state: "Puducherry",
    city: "Puducherry",
    description: "Exciting opportunity for an AR/VR developer in Puducherry.",
  },
  {
    title: "IT Project Manager",
    state: "Chandigarh",
    city: "Chandigarh",
    description:
      "We're hiring an IT project manager for our Chandigarh office.",
  },
  {
    title: "Cybersecurity Analyst",
    state: "Delhi",
    city: "New Delhi",
    description:
      "Seeking a cybersecurity analyst to join our team in New Delhi.",
  },
  {
    title: "Game Developer",
    state: "Chandigarh",
    city: "Chandigarh",
    description: "Exciting opportunity for a game developer in Chandigarh.",
  },
  {
    title: "Technical Support Engineer",
    state: "Ladakh",
    city: "Leh",
    description:
      "We're hiring a technical support engineer for our Leh office.",
  },
  {
    title: "Mobile Application Tester",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Seeking a mobile application tester to join our team in Kavaratti.",
  },
  {
    title: "Software Configuration Manager",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Exciting opportunity for a software configuration manager in Kavaratti.",
  },
  {
    title: "Software Developer",
    state: "Karnataka",
    city: "Bangalore",
    description:
      "Seeking a skilled software developer to join our team in Bangalore.",
  },
  {
    title: "Data Scientist",
    state: "Maharashtra",
    city: "Mumbai",
    description: "We're hiring a data scientist for our Mumbai office.",
  },
  {
    title: "Web Developer",
    state: "Maharashtra",
    city: "Pune",
    description: "Join our team as a web developer in Pune.",
  },
  {
    title: "UI/UX Designer",
    state: "Tamil Nadu",
    city: "Chennai",
    description: "Exciting opportunity for a UI/UX designer in Chennai.",
  },
  {
    title: "Java Developer",
    state: "Uttar Pradesh",
    city: "Noida",
    description: "Join us as a Java developer in Noida.",
  },
  {
    title: "Network Engineer",
    state: "Telangana",
    city: "Hyderabad",
    description: "Looking for a network engineer to work in Hyderabad.",
  },
  {
    title: "Software Engineer",
    state: "Karnataka",
    city: "Mysore",
    description: "Exciting opportunity for a software engineer in Mysore.",
  },
  {
    title: "Machine Learning Engineer",
    state: "Delhi",
    city: "New Delhi",
    description: "Join our team as a machine learning engineer in New Delhi.",
  },
  {
    title: "Frontend Developer",
    state: "Karnataka",
    city: "Hubli",
    description: "Seeking a frontend developer to join our team in Hubli.",
  },
  {
    title: "Backend Developer",
    state: "Gujarat",
    city: "Ahmedabad",
    description: "We're hiring a backend developer for our Ahmedabad office.",
  },
  {
    title: "Database Administrator",
    state: "Uttar Pradesh",
    city: "Lucknow",
    description: "Join us as a database administrator in Lucknow.",
  },
  {
    title: "DevOps Engineer",
    state: "Maharashtra",
    city: "Nagpur",
    description: "Exciting opportunity for a DevOps engineer in Nagpur.",
  },
  {
    title: "System Administrator",
    state: "Tamil Nadu",
    city: "Coimbatore",
    description:
      "We're hiring a system administrator for our Coimbatore office.",
  },
  {
    title: "Full Stack Developer",
    state: "Andhra Pradesh",
    city: "Visakhapatnam",
    description:
      "Seeking a full stack developer to join our team in Visakhapatnam.",
  },
  {
    title: "Product Manager",
    state: "Telangana",
    city: "Warangal",
    description: "Join us as a product manager in Warangal.",
  },
  {
    title: "UI Developer",
    state: "Kerala",
    city: "Kochi",
    description: "Exciting opportunity for a UI developer in Kochi.",
  },
  {
    title: "UX Researcher",
    state: "Madhya Pradesh",
    city: "Bhopal",
    description: "We're hiring a UX researcher for our Bhopal office.",
  },
  {
    title: "Software Architect",
    state: "Rajasthan",
    city: "Jaipur",
    description: "Seeking a software architect to join our team in Jaipur.",
  },
  {
    title: "Mobile App Developer",
    state: "Uttarakhand",
    city: "Dehradun",
    description: "Join us as a mobile app developer in Dehradun.",
  },
  {
    title: "Cloud Engineer",
    state: "West Bengal",
    city: "Kolkata",
    description: "Exciting opportunity for a cloud engineer in Kolkata.",
  },
  {
    title: "Security Analyst",
    state: "Bihar",
    city: "Patna",
    description: "We're hiring a security analyst for our Patna office.",
  },
  {
    title: "Embedded Systems Engineer",
    state: "Punjab",
    city: "Chandigarh",
    description:
      "Seeking an embedded systems engineer to join our team in Chandigarh.",
  },
  {
    title: "QA Engineer",
    state: "Assam",
    city: "Guwahati",
    description: "Join us as a QA engineer in Guwahati.",
  },
  {
    title: "Data Analyst",
    state: "Jharkhand",
    city: "Ranchi",
    description: "Exciting opportunity for a data analyst in Ranchi.",
  },
  {
    title: "Business Analyst",
    state: "Haryana",
    city: "Gurgaon",
    description: "We're hiring a business analyst for our Gurgaon office.",
  },
  {
    title: "IT Support Specialist",
    state: "Himachal Pradesh",
    city: "Shimla",
    description: "Seeking an IT support specialist to join our team in Shimla.",
  },
  {
    title: "Software Tester",
    state: "Goa",
    city: "Panaji",
    description: "Exciting opportunity for a software tester in Panaji.",
  },
  {
    title: "Automation Engineer",
    state: "Manipur",
    city: "Imphal",
    description: "We're hiring an automation engineer for our Imphal office.",
  },
  {
    title: "Technical Writer",
    state: "Nagaland",
    city: "Kohima",
    description: "Seeking a technical writer to join our team in Kohima.",
  },
  {
    title: "Cybersecurity Engineer",
    state: "Meghalaya",
    city: "Shillong",
    description:
      "Exciting opportunity for a cybersecurity engineer in Shillong.",
  },
  {
    title: "Network Administrator",
    state: "Sikkim",
    city: "Gangtok",
    description: "We're hiring a network administrator for our Gangtok office.",
  },
  {
    title: "IT Consultant",
    state: "Arunachal Pradesh",
    city: "Itanagar",
    description: "Seeking an IT consultant to join our team in Itanagar.",
  },
  {
    title: "Database Developer",
    state: "Mizoram",
    city: "Aizawl",
    description: "Exciting opportunity for a database developer in Aizawl.",
  },
  {
    title: "Cloud Architect",
    state: "Tripura",
    city: "Agartala",
    description: "We're hiring a cloud architect for our Agartala office.",
  },
  {
    title: "Systems Analyst",
    state: "Dadra and Nagar Haveli",
    city: "Silvassa",
    description: "Seeking a systems analyst to join our team in Silvassa.",
  },
  {
    title: "Application Developer",
    state: "Daman and Diu",
    city: "Daman",
    description: "Exciting opportunity for an application developer in Daman.",
  },
  {
    title: "Blockchain Developer",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "We're hiring a blockchain developer for our Kavaratti office.",
  },
  {
    title: "Software Support Specialist",
    state: "Andaman and Nicobar Islands",
    city: "Port Blair",
    description:
      "Seeking a software support specialist to join our team in Port Blair.",
  },
  {
    title: "AR/VR Developer",
    state: "Puducherry",
    city: "Puducherry",
    description: "Exciting opportunity for an AR/VR developer in Puducherry.",
  },
  {
    title: "IT Project Manager",
    state: "Chandigarh",
    city: "Chandigarh",
    description:
      "We're hiring an IT project manager for our Chandigarh office.",
  },
  {
    title: "Cybersecurity Analyst",
    state: "Delhi",
    city: "New Delhi",
    description:
      "Seeking a cybersecurity analyst to join our team in New Delhi.",
  },
  {
    title: "Game Developer",
    state: "Chandigarh",
    city: "Chandigarh",
    description: "Exciting opportunity for a game developer in Chandigarh.",
  },
  {
    title: "Technical Support Engineer",
    state: "Ladakh",
    city: "Leh",
    description:
      "We're hiring a technical support engineer for our Leh office.",
  },
  {
    title: "Mobile Application Tester",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Seeking a mobile application tester to join our team in Kavaratti.",
  },
  {
    title: "Software Configuration Manager",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Exciting opportunity for a software configuration manager in Kavaratti.",
  },
  {
    title: "Software Developer",
    state: "Karnataka",
    city: "Bangalore",
    description:
      "Seeking a skilled software developer to join our team in Bangalore.",
  },
  {
    title: "Data Scientist",
    state: "Maharashtra",
    city: "Mumbai",
    description: "We're hiring a data scientist for our Mumbai office.",
  },
  {
    title: "Web Developer",
    state: "Maharashtra",
    city: "Pune",
    description: "Join our team as a web developer in Pune.",
  },
  {
    title: "UI/UX Designer",
    state: "Tamil Nadu",
    city: "Chennai",
    description: "Exciting opportunity for a UI/UX designer in Chennai.",
  },
  {
    title: "Java Developer",
    state: "Uttar Pradesh",
    city: "Noida",
    description: "Join us as a Java developer in Noida.",
  },
  {
    title: "Network Engineer",
    state: "Telangana",
    city: "Hyderabad",
    description: "Looking for a network engineer to work in Hyderabad.",
  },
  {
    title: "Software Engineer",
    state: "Karnataka",
    city: "Mysore",
    description: "Exciting opportunity for a software engineer in Mysore.",
  },
  {
    title: "Machine Learning Engineer",
    state: "Delhi",
    city: "New Delhi",
    description: "Join our team as a machine learning engineer in New Delhi.",
  },
  {
    title: "Frontend Developer",
    state: "Karnataka",
    city: "Hubli",
    description: "Seeking a frontend developer to join our team in Hubli.",
  },
  {
    title: "Backend Developer",
    state: "Gujarat",
    city: "Ahmedabad",
    description: "We're hiring a backend developer for our Ahmedabad office.",
  },
  {
    title: "Database Administrator",
    state: "Uttar Pradesh",
    city: "Lucknow",
    description: "Join us as a database administrator in Lucknow.",
  },
  {
    title: "DevOps Engineer",
    state: "Maharashtra",
    city: "Nagpur",
    description: "Exciting opportunity for a DevOps engineer in Nagpur.",
  },
  {
    title: "System Administrator",
    state: "Tamil Nadu",
    city: "Coimbatore",
    description:
      "We're hiring a system administrator for our Coimbatore office.",
  },
  {
    title: "Full Stack Developer",
    state: "Andhra Pradesh",
    city: "Visakhapatnam",
    description:
      "Seeking a full stack developer to join our team in Visakhapatnam.",
  },
  {
    title: "Product Manager",
    state: "Telangana",
    city: "Warangal",
    description: "Join us as a product manager in Warangal.",
  },
  {
    title: "UI Developer",
    state: "Kerala",
    city: "Kochi",
    description: "Exciting opportunity for a UI developer in Kochi.",
  },
  {
    title: "UX Researcher",
    state: "Madhya Pradesh",
    city: "Bhopal",
    description: "We're hiring a UX researcher for our Bhopal office.",
  },
  {
    title: "Software Architect",
    state: "Rajasthan",
    city: "Jaipur",
    description: "Seeking a software architect to join our team in Jaipur.",
  },
  {
    title: "Mobile App Developer",
    state: "Uttarakhand",
    city: "Dehradun",
    description: "Join us as a mobile app developer in Dehradun.",
  },
  {
    title: "Cloud Engineer",
    state: "West Bengal",
    city: "Kolkata",
    description: "Exciting opportunity for a cloud engineer in Kolkata.",
  },
  {
    title: "Security Analyst",
    state: "Bihar",
    city: "Patna",
    description: "We're hiring a security analyst for our Patna office.",
  },
  {
    title: "Embedded Systems Engineer",
    state: "Punjab",
    city: "Chandigarh",
    description:
      "Seeking an embedded systems engineer to join our team in Chandigarh.",
  },
  {
    title: "QA Engineer",
    state: "Assam",
    city: "Guwahati",
    description: "Join us as a QA engineer in Guwahati.",
  },
  {
    title: "Data Analyst",
    state: "Jharkhand",
    city: "Ranchi",
    description: "Exciting opportunity for a data analyst in Ranchi.",
  },
  {
    title: "Business Analyst",
    state: "Haryana",
    city: "Gurgaon",
    description: "We're hiring a business analyst for our Gurgaon office.",
  },
  {
    title: "IT Support Specialist",
    state: "Himachal Pradesh",
    city: "Shimla",
    description: "Seeking an IT support specialist to join our team in Shimla.",
  },
  {
    title: "Software Tester",
    state: "Goa",
    city: "Panaji",
    description: "Exciting opportunity for a software tester in Panaji.",
  },
  {
    title: "Automation Engineer",
    state: "Manipur",
    city: "Imphal",
    description: "We're hiring an automation engineer for our Imphal office.",
  },
  {
    title: "Technical Writer",
    state: "Nagaland",
    city: "Kohima",
    description: "Seeking a technical writer to join our team in Kohima.",
  },
  {
    title: "Cybersecurity Engineer",
    state: "Meghalaya",
    city: "Shillong",
    description:
      "Exciting opportunity for a cybersecurity engineer in Shillong.",
  },
  {
    title: "Network Administrator",
    state: "Sikkim",
    city: "Gangtok",
    description: "We're hiring a network administrator for our Gangtok office.",
  },
  {
    title: "IT Consultant",
    state: "Arunachal Pradesh",
    city: "Itanagar",
    description: "Seeking an IT consultant to join our team in Itanagar.",
  },
  {
    title: "Database Developer",
    state: "Mizoram",
    city: "Aizawl",
    description: "Exciting opportunity for a database developer in Aizawl.",
  },
  {
    title: "Cloud Architect",
    state: "Tripura",
    city: "Agartala",
    description: "We're hiring a cloud architect for our Agartala office.",
  },
  {
    title: "Systems Analyst",
    state: "Dadra and Nagar Haveli",
    city: "Silvassa",
    description: "Seeking a systems analyst to join our team in Silvassa.",
  },
  {
    title: "Application Developer",
    state: "Daman and Diu",
    city: "Daman",
    description: "Exciting opportunity for an application developer in Daman.",
  },
  {
    title: "Blockchain Developer",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "We're hiring a blockchain developer for our Kavaratti office.",
  },
  {
    title: "Software Support Specialist",
    state: "Andaman and Nicobar Islands",
    city: "Port Blair",
    description:
      "Seeking a software support specialist to join our team in Port Blair.",
  },
  {
    title: "AR/VR Developer",
    state: "Puducherry",
    city: "Puducherry",
    description: "Exciting opportunity for an AR/VR developer in Puducherry.",
  },
  {
    title: "IT Project Manager",
    state: "Chandigarh",
    city: "Chandigarh",
    description:
      "We're hiring an IT project manager for our Chandigarh office.",
  },
  {
    title: "Cybersecurity Analyst",
    state: "Delhi",
    city: "New Delhi",
    description:
      "Seeking a cybersecurity analyst to join our team in New Delhi.",
  },
  {
    title: "Game Developer",
    state: "Chandigarh",
    city: "Chandigarh",
    description: "Exciting opportunity for a game developer in Chandigarh.",
  },
  {
    title: "Technical Support Engineer",
    state: "Ladakh",
    city: "Leh",
    description:
      "We're hiring a technical support engineer for our Leh office.",
  },
  {
    title: "Mobile Application Tester",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Seeking a mobile application tester to join our team in Kavaratti.",
  },
  {
    title: "Software Configuration Manager",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Exciting opportunity for a software configuration manager in Kavaratti.",
  },
  {
    title: "Software Developer",
    state: "Karnataka",
    city: "Bangalore",
    description:
      "Seeking a skilled software developer to join our team in Bangalore.",
  },
  {
    title: "Data Scientist",
    state: "Maharashtra",
    city: "Mumbai",
    description: "We're hiring a data scientist for our Mumbai office.",
  },
  {
    title: "Web Developer",
    state: "Maharashtra",
    city: "Pune",
    description: "Join our team as a web developer in Pune.",
  },
  {
    title: "UI/UX Designer",
    state: "Tamil Nadu",
    city: "Chennai",
    description: "Exciting opportunity for a UI/UX designer in Chennai.",
  },
  {
    title: "Java Developer",
    state: "Uttar Pradesh",
    city: "Noida",
    description: "Join us as a Java developer in Noida.",
  },
  {
    title: "Network Engineer",
    state: "Telangana",
    city: "Hyderabad",
    description: "Looking for a network engineer to work in Hyderabad.",
  },
  {
    title: "Software Engineer",
    state: "Karnataka",
    city: "Mysore",
    description: "Exciting opportunity for a software engineer in Mysore.",
  },
  {
    title: "Machine Learning Engineer",
    state: "Delhi",
    city: "New Delhi",
    description: "Join our team as a machine learning engineer in New Delhi.",
  },
  {
    title: "Frontend Developer",
    state: "Karnataka",
    city: "Hubli",
    description: "Seeking a frontend developer to join our team in Hubli.",
  },
  {
    title: "Backend Developer",
    state: "Gujarat",
    city: "Ahmedabad",
    description: "We're hiring a backend developer for our Ahmedabad office.",
  },
  {
    title: "Database Administrator",
    state: "Uttar Pradesh",
    city: "Lucknow",
    description: "Join us as a database administrator in Lucknow.",
  },
  {
    title: "DevOps Engineer",
    state: "Maharashtra",
    city: "Nagpur",
    description: "Exciting opportunity for a DevOps engineer in Nagpur.",
  },
  {
    title: "System Administrator",
    state: "Tamil Nadu",
    city: "Coimbatore",
    description:
      "We're hiring a system administrator for our Coimbatore office.",
  },
  {
    title: "Full Stack Developer",
    state: "Andhra Pradesh",
    city: "Visakhapatnam",
    description:
      "Seeking a full stack developer to join our team in Visakhapatnam.",
  },
  {
    title: "Product Manager",
    state: "Telangana",
    city: "Warangal",
    description: "Join us as a product manager in Warangal.",
  },
  {
    title: "UI Developer",
    state: "Kerala",
    city: "Kochi",
    description: "Exciting opportunity for a UI developer in Kochi.",
  },
  {
    title: "UX Researcher",
    state: "Madhya Pradesh",
    city: "Bhopal",
    description: "We're hiring a UX researcher for our Bhopal office.",
  },
  {
    title: "Software Architect",
    state: "Rajasthan",
    city: "Jaipur",
    description: "Seeking a software architect to join our team in Jaipur.",
  },
  {
    title: "Mobile App Developer",
    state: "Uttarakhand",
    city: "Dehradun",
    description: "Join us as a mobile app developer in Dehradun.",
  },
  {
    title: "Cloud Engineer",
    state: "West Bengal",
    city: "Kolkata",
    description: "Exciting opportunity for a cloud engineer in Kolkata.",
  },
  {
    title: "Security Analyst",
    state: "Bihar",
    city: "Patna",
    description: "We're hiring a security analyst for our Patna office.",
  },
  {
    title: "Embedded Systems Engineer",
    state: "Punjab",
    city: "Chandigarh",
    description:
      "Seeking an embedded systems engineer to join our team in Chandigarh.",
  },
  {
    title: "QA Engineer",
    state: "Assam",
    city: "Guwahati",
    description: "Join us as a QA engineer in Guwahati.",
  },
  {
    title: "Data Analyst",
    state: "Jharkhand",
    city: "Ranchi",
    description: "Exciting opportunity for a data analyst in Ranchi.",
  },
  {
    title: "Business Analyst",
    state: "Haryana",
    city: "Gurgaon",
    description: "We're hiring a business analyst for our Gurgaon office.",
  },
  {
    title: "IT Support Specialist",
    state: "Himachal Pradesh",
    city: "Shimla",
    description: "Seeking an IT support specialist to join our team in Shimla.",
  },
  {
    title: "Software Tester",
    state: "Goa",
    city: "Panaji",
    description: "Exciting opportunity for a software tester in Panaji.",
  },
  {
    title: "Automation Engineer",
    state: "Manipur",
    city: "Imphal",
    description: "We're hiring an automation engineer for our Imphal office.",
  },
  {
    title: "Technical Writer",
    state: "Nagaland",
    city: "Kohima",
    description: "Seeking a technical writer to join our team in Kohima.",
  },
  {
    title: "Cybersecurity Engineer",
    state: "Meghalaya",
    city: "Shillong",
    description:
      "Exciting opportunity for a cybersecurity engineer in Shillong.",
  },
  {
    title: "Network Administrator",
    state: "Sikkim",
    city: "Gangtok",
    description: "We're hiring a network administrator for our Gangtok office.",
  },
  {
    title: "IT Consultant",
    state: "Arunachal Pradesh",
    city: "Itanagar",
    description: "Seeking an IT consultant to join our team in Itanagar.",
  },
  {
    title: "Database Developer",
    state: "Mizoram",
    city: "Aizawl",
    description: "Exciting opportunity for a database developer in Aizawl.",
  },
  {
    title: "Cloud Architect",
    state: "Tripura",
    city: "Agartala",
    description: "We're hiring a cloud architect for our Agartala office.",
  },
  {
    title: "Systems Analyst",
    state: "Dadra and Nagar Haveli",
    city: "Silvassa",
    description: "Seeking a systems analyst to join our team in Silvassa.",
  },
  {
    title: "Application Developer",
    state: "Daman and Diu",
    city: "Daman",
    description: "Exciting opportunity for an application developer in Daman.",
  },
  {
    title: "Blockchain Developer",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "We're hiring a blockchain developer for our Kavaratti office.",
  },
  {
    title: "Software Support Specialist",
    state: "Andaman and Nicobar Islands",
    city: "Port Blair",
    description:
      "Seeking a software support specialist to join our team in Port Blair.",
  },
  {
    title: "AR/VR Developer",
    state: "Puducherry",
    city: "Puducherry",
    description: "Exciting opportunity for an AR/VR developer in Puducherry.",
  },
  {
    title: "IT Project Manager",
    state: "Chandigarh",
    city: "Chandigarh",
    description:
      "We're hiring an IT project manager for our Chandigarh office.",
  },
  {
    title: "Cybersecurity Analyst",
    state: "Delhi",
    city: "New Delhi",
    description:
      "Seeking a cybersecurity analyst to join our team in New Delhi.",
  },
  {
    title: "Game Developer",
    state: "Chandigarh",
    city: "Chandigarh",
    description: "Exciting opportunity for a game developer in Chandigarh.",
  },
  {
    title: "Technical Support Engineer",
    state: "Ladakh",
    city: "Leh",
    description:
      "We're hiring a technical support engineer for our Leh office.",
  },
  {
    title: "Mobile Application Tester",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Seeking a mobile application tester to join our team in Kavaratti.",
  },
  {
    title: "Software Configuration Manager",
    state: "Lakshadweep",
    city: "Kavaratti",
    description:
      "Exciting opportunity for a software configuration manager in Kavaratti.",
  },
];
