import React from "react";
import "./HowToApply.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { HTADATA, JOBSEEKERINFODATA } from "./HTAData";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HowToApply = () => {
  return (
    <div className="howToApply">
      <Helmet>
        <title>Step-by-Step Guidance</title>
        <meta
          name="description"
          content="Ready to make your mark? Get step-by-step guidance on applying for open positions."
        />
        <meta
          name="keywords"
          content="Apply now, Job application steps, Application process, Apply online"
        />
      </Helmet>
      <div className="header-container">
        <div className="">
          <Header />
        </div>
      </div>
      <div className="HTABanner">
        <div className="wrapper">
          <h2>
            Job Seekers: Explore Exciting Opportunities in Technology & IT –
            Apply Now!
          </h2>
          <p>
            Discover Your Ideal Role with Tech Sierra Staffing & Recruiting |
            Explore IT Opportunities Today
          </p>
        </div>
      </div>
      <div className="HTAContent">
        <div className="wrapper">
          <p>
            Tech Sierra Staffing & Recruiting Makes Applying as Easy as 1, 2, 3!
          </p>
          <p>
            When you partner with Tech Sierra Staffing & Recruiting, you gain
            access to an exceptional technology recruitment team dedicated to
            understanding your career goals and requirements. Founded by
            passionate recruitment professionals with extensive networks, we
            provide expert guidance and support to both our client companies and
            candidates. Our strength lies in linking you with opportunities at
            local, national, and remote businesses and organizations.
          </p>
          <p>
            We recognize that job hunting can be daunting and overwhelming. It's
            our privilege to collaborate with you as you embark on this journey.
            Whether you're seeking a temporary position, transitioning to a new
            industry, or advancing your career, we have the connections and
            resources to support you every step of the way.
          </p>
          <p>
            At Tech Sierra Staffing & Recruiting, we boast extensive connections
            with top industry decision-makers. We cultivate lasting
            relationships with leading brands and corporations to guarantee that
            we match you with the ideal employer.
          </p>
          <p>
            Please be aware: while our aim is to assist as many candidates as
            possible, during talent searches, interview slots are reserved for
            the most qualified applicants who meet our client's hiring criteria
            and industry preferences due to the high volume of applications. If
            you are not selected for an interview, we encourage you to explore
            other available positions with us online! Many of our career
            opportunities are competitive, and it's our responsibility to
            conduct a thorough candidate screening process on behalf of our
            employer clients.
          </p>
          <ul>
            {HTADATA.map((item) => (
              <li key={item.id}>
                <img src={item.imgSrc} alt={item.text} />
                <h4>{item.text}</h4>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="HTASendInfo">
        <div className="wrapper">
          <h4>
            <span>Seize New Opportunities</span>
            <span>By Sharing Your Profile with Us</span>
          </h4>
          <span>
            <Link to={"/contactUs"}>
              <button>send us your info</button>
            </Link>
          </span>
        </div>
      </div>
      <div className="seeker-information">
        <div className="wrapper">
          <h3>
            <span>more job seeker information</span>
          </h3>
          <ul>
            {JOBSEEKERINFODATA.map((item) => (
              <li key={item.id}>
                <Link to={item.path}>{item.content}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowToApply;
