import React from "react";
import "./TemporaryStaffingLists.css";
const TemporaryStaffingLists = ({ subTitle, subPara }) => {
  return (
    //    <ul>
    //     <li>
    <div className="lists">
      <span className="TemporaryStaffingLists-subTitle">{subTitle}: </span>
      <p className="TemporaryStaffingLists-subPara">{subPara}</p>
    </div>
    //     </li>
    //    </ul>
  );
};

export default TemporaryStaffingLists;
