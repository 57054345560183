import React from "react";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import TemporaryStuffingParaSection from "../../../Components/TemporaryStaffingFunction/TemporaryStuffingParaSection/TemporaryStuffingParaSection";
import TemporaryStuffingImgSection from "../../../Components/TemporaryStaffingFunction/TemporaryStuffingImgSection/TemporaryStuffingImgSection";
import TemporaryStaffingAboveFooter from "../../../Components/TemporaryStaffingFunction/TemporaryStaffingAboveFooter/TemporaryStaffingAboveFooter";
import "./Ai.css";
import StartupImgOne from "../../../Assets/Images/Ai/AI-Staffing-Agency.jpg";
import StartupImgTwo from "../../../Assets/Images/Ai/AI-Temporary-Staffing.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

const Ai = () => {
  return (
    <div>
      <div className="header-container">
        <Helmet>
          <title>Contract Staffing</title>
          <meta
            name="description"
            content="Need temporary solutions? Our Temporary Staffing page offers tailored solutions to meet your short-term staffing needs. From skilled professionals to flexible arrangements, we're here to provide reliable support when you need it most."
          />
          <meta
            name="keywords"
            content="temporary workforce,
        seasonal workers,
        flexible staffing solutions,
        temporary staffing near me,
        temporary workers near me,
        temporary employment near me,
        seasonal work opportunities,
        temporary employment agencies nyc,
        temporary employment opportunities,
        temporary employment jobs,
        seasonal work ups"
          />
        </Helmet>
        <div className="">
          <Header />
        </div>
      </div>
      <div className="itContainer wrapper ai-ctn">
        {/* <TemporaryStuffingHeading
          h1={["Award-Winning AI Staffing Agency for All India Locations"]}
          h3={[
            "Discover the Future of AI Innovation with Our Expert AI (Artificial Intelligence) Professionals for Interim and Temporary Staffing Needs.",
          ]}
        /> */}
        <div className="itContentContainer">
          <div className="itLeftContainer">
            <TemporaryStuffingParaSection
              MainHeading="Top-tier AI Professionals"
              paragraph1="At Tech Sierra Staffing & Recruiting, we specialize in delivering outstanding staffing solutions in the field of Artificial Intelligence (AI), empowering organizations like yours with access to top-tier AI talent. In today's rapidly evolving tech landscape, leveraging AI has become imperative for businesses to thrive in a competitive market. Our staffing services and recruitment processes are carefully crafted to match you with highly skilled AI professionals who can spearhead innovation, streamline processes, and unlock new avenues for growth in your organization."
            />
            <TemporaryStuffingParaSection
              MainHeading="Unique Methodology"
              paragraph1="Tech Sierra Staffing & Recruiting employs a tailored and holistic method in our AI staffing services. Recognizing the unique AI requirements and strategic objectives of each organization, we engage in close collaboration with our clients. Our recruiters and AI specialists work closely with you to grasp your business needs, corporate ethos, and technical prerequisites. This enables us to meticulously select and present candidates equipped with the precise expertise, skills, and mindset necessary to bolster your AI endeavors."
            />
            <TemporaryStuffingParaSection
              MainHeading="Leading-edge AI Talent"
              paragraph1="At Tech Sierra Staffing & Recruiting, our extensive network comprises top-tier AI professionals who are leaders in their domain. Whether you're in need of outstanding AI engineers, skilled data scientists, proficient machine learning experts, or experienced AI project managers, we have the resources and connections to seamlessly connect you with the most suitable candidates. Our meticulous screening process guarantees that we present individuals who not only possess exceptional technical abilities but also resonate with your company's values and goals."
            />
            <TemporaryStuffingParaSection
              MainHeading="Efficient Hiring Workflow"
              paragraph1="Acknowledging the importance of efficiency in staffing, we at Tech Sierra oversee every facet of the hiring journey. From sourcing and evaluating candidates to conducting comprehensive interviews and ensuring seamless onboarding, our team handles it all. Our objective is to streamline the staffing process, freeing you to focus on your core business operations."
            />
          </div>
          <div className="itRightContainer StartupImgOne ">
            <TemporaryStuffingImgSection
              buttonText={["Find the Best Leader"]}
              btnText={["Request Staff"]}
              buttonLink="/contact-us"
              imageUrl={StartupImgOne}
            />
          </div>
        </div>
        <div className="itContentContainer itImageContentContainer">
          <div className="itRightContainer ai-btm-ctn max-500">
            <TemporaryStuffingImgSection
              buttonText={["Find the Best Leader"]}
              btnText={["Request Staff"]}
              buttonLink="/contact-us"
              imageUrl={StartupImgTwo}
            />
          </div>
          <div className="itLeftContainer">
            <TemporaryStuffingParaSection
              MainHeading="Global AI Talent Showcase"
              paragraph1="At Tech Sierra Staffing & Recruiting, our forte lies in AI temporary staffing, facilitating connections between organizations and exceptional AI professionals capable of catalyzing innovation and business transformation. Our vast network encompasses a variety of AI roles, spanning from AI engineers and data scientists to machine learning experts and computer vision engineers. Whether you require expertise in natural language processing, deep learning, or robotics, our talent pool is abundant with qualified candidates. Recognizing the significance of assembling the perfect team of AI professionals tailored to your specific needs, we are dedicated to assisting you in finding the ideal match to propel your organization forward in the realm of artificial intelligence."
            />
            <div className="itRightContainer ai-btm-ctn min-500">
              <TemporaryStuffingImgSection
                buttonText={["Find the Best Leader"]}
                btnText={["Request Staff"]}
                buttonLink="/contact-us"
                imageUrl={StartupImgTwo}
              />
            </div>
            <ul className="ai-job-list">
              <li>AI Engineer</li>
              <li>Data Scientist (AI)</li>
              <li>Machine Learning Engineer</li>
              <li>Natural Language Processing Specialist</li>
              <li>Computer Vision Engineer</li>
              <li>AI Research Scientist</li>
              <li>Deep Learning Engineer</li>
              <li>AI Project Manager</li>
              <li>Robotics Engineer (AI)</li>
              <li>AI Software Developer</li>
              <li>AI Analyst</li>
              <li>AI Consultant</li>
              <li>AI Product Manager</li>
              <li>AI Architect</li>
              <li>AI Operations Manager</li>
              <li>AI Ethics Specialist</li>
              <li>AI Quality Assurance Engineer</li>
              <li>AI Integration Specialist</li>
              <li>AI Solutions Architect</li>
              <li>AI Business Strategist</li>
            </ul>
            <Link
              to="/apply/search-it-jobs"
              target="_self"
              className="startUp-las"
              onClick={scrollToTop}
            >
              Launch A Search
            </Link>
          </div>
        </div>
        <p className="ai-btm-para">
          Ready to harness the complete power of AI for your organization or
          startup? Our dedicated team is ready to assist you in assembling a
          skilled and visionary AI workforce that will propel your organization
          to unprecedented growth and success. Discover the Tech Sierra
          Technology advantage in AI staffing and recruitment – your reliable
          ally in fostering AI excellence. Reach out to us today to explore your
          AI temporary staffing requirements.
        </p>
        <TemporaryStaffingAboveFooter
          bottomLinks={[
            {
              text: "REQUEST A STAFF | START A SEARCH",
              url: "/apply/search-it-jobs",
            },
            { text: "Tech leadership roles", url: "/" },
            { text: "our proven appraoch", url: "/about-us" },
          ]}
          h2={["Learn More"]}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Ai;
