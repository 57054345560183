import React from "react";
import "./TemporaryStuffingParaSection.css";

const TemporaryStuffingParaSection = ({
  paragraph1,
  paragraph2,
  secondPara,
  h2,
  MainHeading,
}) => {
  return (
    <div className="temporaryStuffingParaSection">
      <h2>{MainHeading}</h2>
      <p>{paragraph1}</p>
      <p>{paragraph2}</p>
      <div className="secondPara">
        <h2>{h2}</h2>
        {secondPara}
      </div>
    </div>
  );
};

export default TemporaryStuffingParaSection;
