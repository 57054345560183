import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Pages/Home/home";
import Contact from "./Pages/Contact/contact";
import OurMissionValue from "./Pages/About/OurMission&Value/OurMissionValue";
import IT from "./Pages/About/IT/IT";
import StartUp from "./Pages/TemporaryStaffing/StartUp/StartUp";
import Ai from "./Pages/TemporaryStaffing/Aitest/Ai";
import HowToApply from "./Pages/Apply/HowToApply/HowToApply";
import ItJobsInterview from "./Pages/Apply/ITJobsInterviewTips/ItJobsInterview";
import SearchItJobs from "./Pages/Apply/Search_IT_JOBs/SearchItJobs";
import PositionWeRecurit from "./Pages/Apply/PositionWeRecurit/PositionWeRecurit";
import ApplyWithUs from "./Pages/Apply/ApplyWithUs/ApplyWithUs";
import Hire from "./Pages/Hire/Hire";
import ContactUs from "./Components/ContactUs/ContactUs";
import { Helmet } from "react-helmet";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Tech Sierra - Technology Staffing & Recruiting</title>
        <meta
          name="description"
          content="
          At Tech Sierra, we redefine the standards of IT staffing and recruiting in USA. Our mission is simple: to connect exceptional talent with innovative companies, driving mutual success and growth. With a keen understanding of the dynamic IT market, we specialize in matching skilled professionals with rewarding career opportunities that align with their expertise and aspirations. Powered by a commitment to excellence and a personalized approach, we go above and beyond to exceed expectations and deliver results that propel businesses forward. Partner with Tech Sierra today and experience the difference firsthand."
        />
        <meta
          name="keywords"
          content="staffing services in us,
      Techsierra staffing services, 
      TechSierra,
      Technology staffing & recruiting US, 
      US - tech staffing usa,
      IT recruitment USA,
      RPO service in USA,
      staffing & recruiting agency in USA,
      tech sierra,
      Tech Sierra,
      Sierra Tech,
      Tech Sierra India,
      USA,
      Indian technology,
      Top tech startups in the USA,
      USA tech
      TechSierra.in
      TechSierra.us
      Techsierra USA,
      TechSierra USA,
      techsierra usa,
      TechSierra in USA,
      USA Techsierra,
      USA TechSierra,
      Tech Sierra USA,
      Tech Sierra usa,
      tech sierra usa,
      USA Tech Sierra
      TechSierra services in USA,
      TechSierra US,
      techsierra us,
      US Techsierra
      Techsierra us company,
      US IT Solutions,
      US Staffing & Recruiting , 
      IT company in US,
      Software comapany US,
      US IT company
      cheap staffing services,
      best staffing service,
      staff services near me,
      staffing services memphis tn,
      staffing agency lexington ky,
    "
        />
      </Helmet>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/contact-us" element={<Contact />} exact />
        <Route path="/hire" element={<Hire />} exact />
        <Route path="/about-us" element={<OurMissionValue />} exact />
        <Route path="/temporary-staffing/it" element={<IT />} exact />
        <Route path="/permanent-placement" element={<StartUp />} exact />
        <Route path="/temporary-staffing" element={<Ai />} exact />
        <Route path="/apply/apply-with-us" element={<ApplyWithUs />} exact />
        <Route path="/apply/how-to-apply" element={<HowToApply />} exact />
        <Route
          path="/apply/it-job-interview-tips"
          element={<ItJobsInterview />}
          exact
        />
        <Route path="/apply/search-it-jobs" element={<SearchItJobs />} exact />
        <Route
          path="/apply/position-we-recruit"
          element={<PositionWeRecurit />}
          exact
        />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/contactUs" element={<ContactUs />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
