import React from "react";
import "./SectionThree.css";
import ImageOne from "../../Assets/Images/EpowerSectionImages/Temporary-IT-Technology-Staffing.png";
import ImageTwo from "../../Assets/Images/EpowerSectionImages/EdTech-blog.png";
import ImageThree from "../../Assets/Images/EpowerSectionImages/Technology-Executive-Search-Services.png";
import ImageFour from "../../Assets/Images/EpowerSectionImages/Online-Courses-blog.png";
const SectionThree = () => {
  return (
    <div className="EmpowerCtn">
      <div className="wrapper">
        <h2>
          Transform your team with elite IT staffing and tech recruitment
          solutions meticulously curated by Tech Sierra's visionary leaders.
          Seamlessly integrate top-tier talent and unlock unparalleled success
          with our bespoke tech services tailored to your unique needs.
        </h2>
        <ul className="MainCtn">
          <li>
            <a href="#FIXME">
              <img src={ImageOne} alt="Temporary-IT-Technology-Staffing" />
            </a>
            <a href="#FIXME" className="EmpowerTxt">
              <h3>IT & Engineering Staffing</h3>
              <p>
                Empower your IT team with elite contract professionals, tailored
                for long-term tech contracts. Experience flexible hiring options
                nationwide, including remote placements, to fuel your success.
              </p>
            </a>
          </li>
          <li>
            <a href="#FIXME">
              <img src={ImageTwo} alt="EdTech-blog" />
            </a>
            <a href="#FIXME" className="EmpowerTxt">
              <h3>Local & National IT Recuriting</h3>
              <p>
                Unlock national talent solutions tailored to your core
                technology, engineering, and IT requisites, spanning individual
                contributors to managerial and leadership roles. Elevate your
                team with our comprehensive staffing expertise, ensuring
                seamless integration of top-tier professionals at every level.
              </p>
            </a>
          </li>
          <li>
            <a href="#FIXME">
              <img
                src={ImageThree}
                alt="Technology-Executive-Search-Services"
              />
            </a>
            <a href="#FIXME" className="EmpowerTxt">
              <h3>Tech Titans: Elite Executive Search</h3>
              <p>
                Experience unparalleled excellence in executive search for
                technology, engineering, and IT sectors. Our award-winning
                services connect expanding corporations, startups, and
                organizations nationwide with top-tier executive talent, setting
                the standard for success.
              </p>
            </a>
          </li>
          <li>
            <a href="#FIXME">
              <img src={ImageFour} alt="Online-Courses-blog" />
            </a>
            <a href="#FIXME" className="EmpowerTxt">
              <h3>Remote & Onsite Technology Staff</h3>
              <p>
                Join our Tech Sierra team and access elite remote talent from
                Engineering to Executive Leadership. Empower innovation with
                top-tier professionals. Shape your organization's future with
                bespoke talent solutions.
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionThree;
