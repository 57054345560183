import React, { useState } from "react";
import "./ContactUsForm.css";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    message: "",
    email: "",
    phone: "",
  });

  const [file, setFiles] = useState({
    uploadResume: "",
  });

  const { fullName, message, email, phone } = formData;

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnFiles = (event) => {
    const { name, files } = event.target;
    setFiles({ ...file, [name]: files });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    console.log(fullName, message, email, phone, file.uploadResume);

    setFormData({
      fullName: "",
      message: "",
      email: "",
      phone: "",
    });
    setFiles({
      uploadResume: "",
    });
  };

  return (
    <div>
      <div className="applyForm">
        <div className="wrapper">
          <h3>Contact Us</h3>
          <form onSubmit={handleOnSubmit}>
            <div className="apply-form-group">
              <div className="input-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Enter Full Name"
                  value={formData.fullName}
                  onChange={handleOnChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={handleOnChange}
                  required
                />
              </div>
            </div>

            <div className="apply-form-group">
              <div className="input-group">
                <label htmlFor="phone">phone</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Enter Phone Number"
                  value={formData.phone}
                  onChange={handleOnChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="uploadResume">Please Upload Your Resume</label>
                <input
                  type="file"
                  name="uploadResume"
                  id="uploadResume"
                  onChange={handleOnFiles}
                  required
                />
              </div>
            </div>

            <div className="apply-form-group textarea">
              <div className="input-group">
                <label htmlFor="LikeToShare">Enter Message</label>
                <textarea
                  rows={8}
                  name="LikeToShare"
                  id="LikeToShare"
                  value={formData.LikeToShare}
                  onChange={handleOnChange}
                  required
                ></textarea>
              </div>
            </div>

            <div className="apply-btn">
              <button type="submit">submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
