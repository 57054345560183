import React from "react";
import "./ContactUs.css";
import ContactUsForm from "../ContactUsForm/ContactUsForm";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div className="contactUsPage">
      <div className="header-container">
      <Helmet>
        <title>Get in Touch</title>
        <meta name="description" content="Connect with us effortlessly! Our Contact Us page is your gateway to seamless communication. Reach out for inquiries, feedback, or just to say hello. We're here to listen and assist."/>
        <meta name="keywords" content="Contact Us, Reach Out, Connect, Contact Information" />
      </Helmet>
        <div className="">
          <Header />
        </div>
      </div>
      <ContactUsForm />
      <Footer />
    </div>
  );
};

export default ContactUs;
