import React from "react";
import "./TemporaryStuffingImgSection.css";
import { Link } from "react-router-dom";

const TemporaryStuffingImgSection = ({
  buttonText,
  buttonLink,
  imageUrl,
  imageAlt,
  btnText,
}) => {
  return (
    <>
      <div className="button-container">
        <span className="primary-button">{buttonText}</span>
        <p>
          <Link className="employer_cta_button" to={buttonLink}>
            {btnText}
          </Link>
        </p>
      </div>

      <img className="mission-image" src={imageUrl} alt={imageAlt} />
    </>
  );
};

export default TemporaryStuffingImgSection;
