import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import techsierraSmallLogo from "../../Assets/Images/TechSierraLogo.png";
import { NavItems, applyDrop } from "./HeaderItems";
import "./Header.css";

function ApplyDown({ closeDropDown, title }) {
  return (
    <ul
      className={`services-subMenu ${title}`}
      onMouseLeave={() => closeDropDown(false)}
    >
      {applyDrop.map((item) => (
        <li key={item.id}>
          <Link to={item.path} className={item.cName}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}

const Header = () => {
  const [showApplyDrop, setShowApplyDrop] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false); // State for mobile menu
  const location = useLocation(); // Get current location

  const handleMobileMenuToggle = () => {
    setShowMobileMenu((prevState) => !prevState); // Toggle the mobile menu state
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <div className="logo-container">
          <Link className="navbar-brand" to="/">
            <img
              src={techsierraSmallLogo}
              className="logo"
              alt="Tech Sierra"
              width="100%"
              height="auto"
            />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMobileMenuToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${showMobileMenu ? "show" : ""}`}
        >
          <ul className="navbar-nav">
            {NavItems.map((item) => {
              if (item.title === "apply") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onMouseMoveCapture={() => setShowApplyDrop(true)}
                    onMouseLeave={() => setShowApplyDrop(false)}
                  >
                    <Link
                      to={item.path}
                      className={
                        location.pathname === item.path
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      {item.title}
                    </Link>
                    {showApplyDrop && (
                      <ApplyDown
                        closeDropDown={setShowApplyDrop}
                        title={item.title}
                      />
                    )}
                  </li>
                );
              }
              return (
                <li key={item.id} className={item.cName}>
                  <Link
                    to={item.path}
                    className={
                      location.pathname === item.path
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
