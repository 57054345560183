import React from "react";
import Header from "../../Components/Header/Header";
import Banner from "../../Components/Banner/Banner";
import SectionThree from "../../Components/SectionThree/SectionThree";
import TextBox from "../../Components/TextBox/TextBox";
// import SliderComponent from "../../Components/SliderComponent/SliderComponent";
import RecruitingSpecialties from "../../Components/RecruitingSpecialties/RecruitingSpecialties";
import Footer from "../../Components/Footer/Footer";
import hireStaff from "../../Assets/Images/Homepage/hire-staff.png";
import startaSearch from "../../Assets/Images/Homepage/home-tech-whychoose.jpg";
import techSearch from "../../Assets/Images/Homepage/launchYourTechSearch.jpg";

const marginRight = window.innerWidth < 600 ? "0" : "40%";
const marginLeft = window.innerWidth < 600 ? "0" : "50%";
const padding0 = window.innerWidth < 600 ? "0" : "10%";
const marginLeft10 = window.innerWidth < 1050 ? "0" : "10%";
const parawidth = window.innerWidth < 1050 ? "60" : "30%";
const parawidth40 = window.innerWidth < 1050 ? "40" : "30%";

const Home = () => {
  const heading = ["Trusted Legacy"];
  const headingTwo = ["Why Choose Our Industry-Leading IT Staffing"];
  const headingThree = ["Elite Tech Talent Networking"];
  const headingFour = ["Leading-edge Tech Professionals"];
  const paragraphs = [
    //para 1
    "Tech Sierra is a premier name in the realm of IT staffing, renowned nationwide for its expertise in recruiting top talent across various domains such as engineering, IT, data, security, product development, design, informatics, and executive leadership. Our strategic outbound recruitment strategy guarantees the assembly of dynamic technology teams perfectly aligned with your specific requirements.",
    //para 2
    "Leverage our comprehensive national talent resources and localized connections to fulfill on-site needs across various roles, including individual contributors, managers, and executive leaders. Whether it's architecture, development, programming, systems, security, web, or apps, our direct placement solutions grant you access to elite professionals ready to propel your business forward.",
    //para 3
    "Benefit from our expansive reach across the nation, backed by a team of dedicated US-based remote tech recruiters. We cater to every US city and emerging technology hub, ensuring widespread coverage for your staffing needs. Recognized for our unwavering dedication to excellence, Tech Sierra has garnered continuous acclaim from reputable publications. For sixteen years running, The Business Times has lauded us as a premier technology staffing agency, offering Retained, Contingent, and Temporary Services. Moreover, we're proud recipients of a coveted spot on Forbes' esteemed list of Best Executive Recruiting Firms in the United States, alongside the prestigious Best of Staffing award.",
    //para 4
    "At Tech Sierra, we're dedicated to guiding you through the search process as your reliable partner. Our aim is to link you with top-tier IT, engineering, and technology professionals. With our proven track record in sourcing exceptional talent across diverse roles, we stand as the preferred choice for industry-leading corporations and innovative startups alike.",
    //para 5
    "Discover cutting-edge technology staffing solutions customized to suit your specific needs at Tech Sierra. With our seasoned team of technology recruiters, we're equipped to offer immediate assistance at any stage of the hiring process, no matter the size of your needs.",
    //para 6
    "Join forces with Tech Sierra today and unlock the power of a robust technology team that will drive your business to unprecedented heights of success!",
  ];

  const paraTwo = [
    "At Tech Sierra, we excel in recruiting experienced engineering, IT, and technology professionals and leaders for technology-driven companies and departments. Our technology recruiting triumphs are rooted in our steadfast dedication to excellence across all aspects of our operations. Our focus is on presenting you with exceptional technology talent options tailored to your unique operational needs and company ethos. With a clear mission to efficiently and thoughtfully connect you with the finest technology talent within your financial parameters, we offer premier tech staffing solutions for businesses, brands, and organizations. Leveraging vast local and nationwide candidate pools and diverse networks, we bring a wealth of exceptional tech talent to meet your recruitment needs.",
    "Tech Sierra Staffing is built on the strong connections we've nurtured with startups, corporations, venture capitalists, and industry leaders. We grant our tech clients unmatched access to national and local talent networks, offering over 14,000,000 qualified candidates. With a vast pool of vetted IT and technology professionals actively seeking opportunities, we cater to a diverse array of tech needs. Our skilled recruiters specialize in placements spanning various technologies, infrastructures, apps, startups, and services. We pride ourselves on recruiting with the latest tech and consistently delivering top-notch talent to meet our clients' demands. Whether you need temporary IT contractors or executive placements, we're here to connect you with the talent you seek.",
  ];

  const paraThreePartOne = [
    "In today's fiercely competitive technology landscape, having a skilled tech recruitment and staffing ally is imperative. Tech Sierra excels in actively networking, recruiting, and placing top-tier IT talent across diverse verticals and specialized teams.",
    "Tech Sierra's committed tech recruiters will harness their extensive industry expertise and broad networks of skilled candidates to conduct tailored talent searches for precise skill requirements. Feel free to dub us head-hunters – we embrace the term!",
    "We deliver meticulously vetted technology experts who are not readily available on job boards or conventional talent repositories. Our clients consistently praise our personalized recruitment focus, transparent communication, thorough methodology, and extensive networks within the tech community.",
    "We facilitate the placement of temporary contract personnel for teams of varying sizes and manage nationwide searches for intricate direct hires and technology executive roles across multiple job openings. With regional presence across the United States, our extensive and diverse technology candidate network is readily available for us to utilize on your behalf. Just provide our expert tech recruiters with the desired candidate background and skill set combination, and we'll handle the rest. Our commitment is to deliver top-notch potential candidates while fostering robust business relationships with both our client partners and talent connections.",
  ];
  const paragraphPartTwo = [
    "We engage with, source, and provide the future leaders in IT, engineering, and creative fields. Our skilled technology candidates possess expertise across a range of emerging technologies, programming languages, hardware, platforms, and environments.",
    "We specialize in recruiting and staffing professionals at every proficiency level across various domains, including web, mobile, software, network, big data, social media, artificial intelligence, product development, emerging technologies, technical support endeavors, and beyond.",

    "Tailored to your environment, organizational ethos, and hiring criteria, we offer specialized and dependable technology and engineering personnel. This unique approach sets us apart from other staffing agencies, establishing us as a formidable industry leader in connecting skilled professionals with tech-centric businesses and organizations.",
  ];

  const buttonText = ["HIRE STAFF NOW"];
  const buttonTextTwo = ["START A SEARCH"];
  const buttonTextThree = ["LAUNCH YOUR TECH SEARCH"];
  // const buttonLink = "/";

  return (
    <div className="homePage">
      <div className="header-container">
        <div className="">
          <Header />
        </div>
      </div>
      <Banner />
      <SectionThree />
      <TextBox
        padding={padding0}
        wrapperCustom="58%"
        images={hireStaff}
        marginLeft="0%"
        width="50%"
        backgroundSize="50% 100%"
        heading={heading}
        paragraphs={paragraphs}
        buttonText={buttonText}
        buttonLink={"/apply/search-it-jobs"}
      />
      <RecruitingSpecialties />
      <TextBox
        images={startaSearch}
        marginLeft={marginLeft}
        // width="30%"
        width={parawidth}
        backgroundSize="115%"
        heading={headingTwo}
        paragraphs={paraTwo}
        buttonText={buttonTextTwo}
        buttonLink={"/apply/search-it-jobs"}
        padding={padding0}
        className="homePagePara2"
      />

      {/* <SliderComponent /> */}
      <TextBox
        images={techSearch}
        marginLeft={marginLeft10}
        // width="28%"
        marginRight={marginRight}
        width={parawidth40}
        backgroundSize="120%"
        heading={headingThree}
        paragraphs={paraThreePartOne}
        headingTwo={headingFour}
        paragraphPartTwo={paragraphPartTwo}
        buttonText={buttonTextThree}
        buttonLink={"/apply/search-it-jobs"}
        padding={padding0}
      />
      <Footer />
    </div>
  );
};

export default Home;
