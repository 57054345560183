/* eslint-disable react/style-prop-object */
import React from "react";
import "./Footer.css";
import { BiLogoLinkedin } from "react-icons/bi";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer_Container">
      <div className="footer_top_holder">
        <div className="top-wrapper">
          <div className="middle-div">
            <p>
              <a className="phoneNumber" href="tel:603-305-3060">
                603-305-3060
              </a>
            </p>
            <br />
            <Link to={"/contact-us"} title="Tech Sierra">
              <p>Join Our Team</p>
            </Link>
            <br />
            <Link to={"/contact-us"} title="Tech Sierra">
              <p>Our Office</p>
            </Link>
            {/* <br />
            <p>Privacy Policy/ Terms of Service</p> */}
            <br />
            <Link to={"/contactUs"} title="Tech Sierra">
              <p>Contact Us</p>
            </Link>
            <br />
            <div className="social-icons">
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/tech-sierra/mycompany/"
                >
                  <BiLogoLinkedin size={30} />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom_holder">
        <p>© 2024 Tech Sierra Staffing, Inc. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
